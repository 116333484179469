<script setup>
// import { ref } from "vue";

import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="contact-us" class="section contact">
      <div class="container" data-aos="fade-up">
        <h2>Contact <span class="cursive">Us</span></h2>
        <!-- <p class="highlight py-5">
          Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do
          eiusmod tempor incididunt ut labore et dolore roipi magna aliqua.
          Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do
        </p> -->
        <div class="big-cards-wrapper mt-4">
          <div class="card unit">
            <a href="https://maps.app.goo.gl/LB3c44R6atNLiQer7" target="_blank">
              <img
                class="card-img-top"
                src="https://placehold.co/600x400/png"
                alt="Card image cap"
                aria-label="map"
            /></a>
            <div class="card-body">
              <h5 class="card-title highlight">
                Office Address <span> - HEAD OFFICE</span>
              </h5>
              <p class="card-text">
                Suite 2, Level 1<br />
                50 Pitt Street <br />
                Sydney, NSW 2000<br />
              </p>
              <p class="card-text">
                <b>Email: </b>
                <a href="mailto:info@monumentpf.com">info@monumentpf.com</a>
              </p>
            </div>
          </div>
          <div class="card unit">
            <a href="https://maps.app.goo.gl/xtVd8HRjMUqhdegt6" target="_blank">
              <img
                class="card-img-top"
                src="https://placehold.co/600x400/png"
                alt="Card image cap"
            /></a>
            <div class="card-body">
              <h5 class="card-title highlight">Mailing address</h5>
              <p class="card-text">
                Level 12, 157 Ann Street<br />
                Brisbane QLD 4000<br />
                Australia
              </p>
              <p class="card-text">
                <b>Email: </b>
                <a href="mailto:info@monumentpf.com">info@monumentpf.com</a>
              </p>
            </div>
          </div>
          <div class="card unit">
            <a href="https://maps.app.goo.gl/YwbZ6UEawzRiB8u1A" target="_blank">
              <img
                class="card-img-top"
                src="https://placehold.co/600x400/png"
                alt="Card image cap"
              />
            </a>
            <div class="card-body">
              <h5 class="card-title highlight">Melbourne</h5>
              <p class="card-text">
                Level 3, 333 Collins Street<br />
                Melbourne VIC 3000<br />
                Australia
              </p>
              <p class="card-text">
                <b>Email: </b>
                <a href="mailto:info@monumentpf.com">info@monumentpf.com</a>
              </p>
            </div>
          </div>
          <div class="card unit">
            <a href="mailto:claims.au@monument.com" target="_blank">
              <img
                class="card-img-top"
                src="https://placehold.co/600x400/png"
                alt="Card image cap"
              />
            </a>
            <div class="card-body">
              <h5 class="card-title highlight">Claims</h5>

              <p class="card-text">
                <b>Email: </b>
                <a href="mailto:claims.au@monument.com"
                  >claims.au@monument.com</a
                >
              </p>
            </div>
          </div>
        </div>

        <div class="card unit mt-4">
          <div class="card-body">
            <h4 class="card-title highlight">Media enquiries</h4>

            <p class="card-text">
              For all media enquiries please contact<br />
              <b>Violeta Fernando</b>
            </p>
            <p class="card-text">
              <b>Email:</b><br />
              <a href="mailto:violeta_fernando@monument.com"
                >violeta_fernando@monument.com</a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- CLARIFY NEXT SECTION -->
    </section>
  </main>

  <FooterSection />
</template>
