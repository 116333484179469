<script>
import { ref } from "vue";

export default {
  setup() {
    const addressToggle = ref(false);

    return {
      addressToggle,
    };
  },
};
</script>

<template>
  <footer id="footer" ref="contacts">
    <div class="container clearfix">
      <div class="row">
        <div class="col-12 col-md-7">
          <div class="row">
            <div class="col-12 col-md-7">
              <div class="address-widget">
                <div class="footer-contact">
                  <div class="row">
                    <div class="col address-widget-btn">
                      <div
                        tag="a"
                        class="footer-toggle-btn"
                        :class="[addressToggle ? '' : 'active']"
                        @click="addressToggle = 0"
                      >
                        <img
                          class="active-icon"
                          src="@/assets/img/location-white.svg"
                          alt="Office address"
                        />
                        <img
                          class="inactive-icon"
                          src="@/assets/img/location-dark.svg"
                          alt="Office address"
                        />
                      </div>
                    </div>
                    <div class="col address-widget-btn">
                      <div
                        tag="a"
                        class="footer-toggle-btn"
                        :class="[addressToggle ? 'active' : '']"
                        @click="addressToggle = 1"
                      >
                        <img
                          class="active-icon"
                          src="@/assets/img/email-white.svg"
                          alt="Postal address"
                        />
                        <img
                          class="inactive-icon"
                          src="@/assets/img/email-dark.svg"
                          alt="Postal address"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div v-if="addressToggle == 0">
                      <h3>OFFICE ADDRESS</h3>
                      <p>
                        Suite 2, Level 1<br />
                        50 Pitt Street Sydney,<br />
                        NSW 2000<br />
                      </p>
                    </div>
                    <div v-if="addressToggle == 1">
                      <h3>POSTAL ADDRESS</h3>
                      <p>
                        PO BOX R1873<br />
                        Sydney<br />
                        NSW 2000
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="footer-link">
                <ul>
                  <li>
                    <a
                      href="./forms/Terms_and_Conditions_Monument_Sept2024.pdf"
                      target="_blank"
                      >Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href="./forms/Privacy_Policy_Monument_Sept24.pdf"
                      target="_blank"
                      >Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="./forms/Monument_FinancialHardshipPolicy_Oct 2024.pdf"
                      target="_blank"
                      >Financial Hardship Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="./forms/Monument_Code_of_Practice_Sept2024.pdf"
                      target="_blank"
                      >Code of Practice
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="afia-badge">
            <img
              src="@/assets/img/AFIA_CodeCompliantLogo_WHITE_RGB.png"
              alt="AFIA Insurance Premium
            Funding Code of Practice Compliant"
            />
            <p>
              We’re proud to be an accredited member of the AFIA Insurance
              Premium Funding Code of Practice.
            </p>
          </div>
          <div class="copyright">
            &copy; Copyright
            <span>Monument {{ new Date().getFullYear() }}</span>
            <span class="px-3">|</span>
            <span> Pty Ltd ABN 20-002-543-606</span>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="credits">
            <div class="info">
              Let's keep the conversation less one-sided:
              <a href="mailto:info@monumentpf.com.au">info@monumentpf.com.au</a
              ><br />
              <a href="tel:+611800664643">1800 664 643</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
