<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";
import GetInTouchSection from "./GetInTouch.vue";
import ClientTestimonialSection from "./ClientTestimonial.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="products-and-services" class="section products mt-5">
      <div class="container" data-aos="fade-up">
        <h2>Our <span class="cursive">Service</span></h2>
        <p class="highlight py-5">
          Having a payment plan for insurance premiums allow businesses to be
          covered for everything they need, rather than what they can afford at
          any given time. Our service provides business with the reassurance and
          peace of mind that they need to navigate through unforeseen
          circumstances.
        </p>
      </div>
      <!-- ======= Section Break ======= -->
      <section
        id="product-section-break"
        class="section product section-break"
        ref="product-section-break"
      >
        <div class="container">
          <h1 class="mb-4">How premium funding works</h1>
          <div class="row product-diamond">
            <hr class="dotted-lines d-none d-md-block" />
            <!-- <div class="col">&nbsp;</div> -->
            <div class="col-5 col-md-2 mx-sm-3">
              <div class="product-feature-wrapper">
                <div class="product-feature">
                  <div class="diamond">
                    <div class="tilted-square colour-1">&nbsp;</div>
                    <span class="diamond-text"
                      ><div class="num colour-1">1</div>
                      Get<br />a quote</span
                    >
                  </div>
                  <br />
                  <p>
                    Ask your insurance broker to provide you with repayment
                    options
                  </p>
                </div>
              </div>
            </div>
            <div class="col-5 col-md-2 mx-sm-3">
              <div class="product-feature-wrapper">
                <div class="product-feature">
                  <div class="diamond">
                    <div class="tilted-square colour-2">&nbsp;</div>
                    <span class="diamond-text"
                      ><div class="num colour-2">2</div>
                      Confirm your<br />Acceptance</span
                    >
                  </div>
                  <br />
                  <p>
                    Go online and 'accept' from your phone, tablet or computer
                    with your preferred payment method
                  </p>
                </div>
              </div>
            </div>
            <div class="col-5 col-md-2 mx-sm-3">
              <div class="product-feature-wrapper">
                <div class="product-feature">
                  <div class="diamond">
                    <div class="tilted-square colour-3">&nbsp;</div>
                    <span class="diamond-text"
                      ><div class="num colour-3">3</div>
                      Fast<br />Approvals</span
                    >
                  </div>
                  <br />
                  <p>
                    Approvals are often confirmed on the same day, and
                    repayments get underway.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-5 col-md-2 mx-sm-3">
              <div class="product-feature-wrapper">
                <div class="product-feature">
                  <div class="diamond">
                    <div class="tilted-square colour-4">&nbsp;</div>
                    <span class="diamond-text"
                      ><div class="num colour-4">4</div>
                      Fast<br />Renewals</span
                    >
                  </div>
                  <br />
                  <p>
                    Next year, your insurance broker will update the amount
                    funded and renew your insurance payments. Easy!
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="col">&nbsp;</div> -->
          </div>
        </div>
      </section>
      <!-- End Section Break -->
      <section>
        <div class="benefit-of-premium-funding container">
          <h2 class="text-center mb-4">The Benefits of Premium Funding</h2>
          <div class="row">
            <div class="col-12 col-md-4 my-3">
              <div class="card">
                <div class="card-body benefit colour-1">
                  <div class="row">
                    <div class="col">
                      <div class="img">
                        <img
                          src="@/assets/img/product-icon-3.svg"
                          alt="Frees up Cash Flow"
                        />
                      </div>
                      <div class="title">
                        <div><strong>Frees up your</strong></div>
                        <div><strong>Cash Flow</strong></div>
                      </div>
                      <p>
                        Approvals are often confirmed on the same day, and
                        repayments get underway.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 my-3">
              <div class="card">
                <div class="card-body benefit colour-2">
                  <div class="row">
                    <div class="col">
                      <div class="img">
                        <img
                          src="@/assets/img/product-icon-2.svg"
                          alt="Fast Approvals"
                        />
                      </div>
                      <div class="title">
                        <div><strong>Easy quoting and</strong></div>
                        <div><strong>Fast Approvals</strong></div>
                      </div>
                      <p>
                        Approvals are often confirmed on the same day, and
                        repayments get underway.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 my-3">
              <div class="card">
                <div class="card-body benefit colour-3">
                  <div class="row">
                    <div class="col">
                      <div class="img">
                        <img
                          src="@/assets/img/product-icon-1.svg"
                          alt="Multiple Repayment Options"
                        />
                      </div>
                      <div class="title">
                        <div><strong>Multiple</strong></div>
                        <div><strong>Repayment Options</strong></div>
                      </div>
                      <p>
                        Approvals are often confirmed on the same day, and
                        repayments get underway.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 my-3">
              <div class="card">
                <div class="card-body benefit colour-4">
                  <div class="row">
                    <div class="col">
                      <div class="img">
                        <img
                          src="@/assets/img/product-icon-4.svg"
                          alt="Avoid fluctuation with Fixed Interest Rates"
                        />
                      </div>
                      <div class="title">
                        <div><strong>Avoid fluctuation with</strong></div>
                        <div><strong>Fixed Interest Rates</strong></div>
                      </div>
                      <p>
                        Approvals are often confirmed on the same day, and
                        repayments get underway.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 my-3">
              <div class="card">
                <div class="card-body benefit colour-5">
                  <div class="row">
                    <div class="col">
                      <div class="img">
                        <img
                          src="@/assets/img/product-icon-5.svg"
                          alt="Combine Multiple Policies into one simple instalment"
                        />
                      </div>
                      <div class="title">
                        <div><strong>Combine Multiple Policies</strong></div>
                        <div><strong>into one simple instalment</strong></div>
                      </div>
                      <p>
                        Approvals are often confirmed on the same day, and
                        repayments get underway.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 my-3">
              <div class="card">
                <div class="card-body benefit colour-6">
                  <div class="row">
                    <div class="col">
                      <div class="img">
                        <img
                          src="@/assets/img/product-icon-6.svg"
                          alt="Tax Deductible Interest charges"
                        />
                      </div>
                      <div class="title">
                        <div><strong>Interest charges can be</strong></div>
                        <div><strong>Tax Deductible</strong></div>
                      </div>
                      <p>
                        Approvals are often confirmed on the same day, and
                        repayments get underway.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ClientTestimonialSection />
      <GetInTouchSection />
    </section>
  </main>

  <FooterSection />
</template>
