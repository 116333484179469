<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";
import GetInTouchSection from "./GetInTouch.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="our-resources" class="section resources">
      <div data-aos="fade-up">
        <div class="container">
          <h2>Our <b>Resources</b></h2>
          <div class="row">
            <div class="col">
              <h3>Below is a selection of forms and resources</h3>
              <p class="highlight py-5">
                If you can't find the information you are looking for, please
                contact our customer service team who will be happy to assist
                you.
              </p>
            </div>
          </div>
        </div>
        <section>
          <div class="container">
            <h2 class="pb-4">Documents & Forms</h2>
            <div class="document-vault">
              <div class="row">
                <div class="col-12 col-md-4">
                  <div class="document-link">
                    <a
                      href="./forms/Monument_Code_of_Practice_Sept2024.pdf"
                      target="_blank"
                      >Code of Practice</a
                    >
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="document-link">
                    <a
                      href="./forms/Terms_and_Conditions_Monument_Sept2024.pdf"
                      target="_blank"
                      >Terms and Conditions</a
                    >
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="document-link">
                    <a
                      href="./forms/Privacy_Policy_Monument_Sept24.pdf"
                      target="_blank"
                      >Privacy Policy</a
                    >
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="document-link">
                    <a
                      href="./forms/Fee_Schedule_Monument_Sept2024.pdf"
                      target="_blank"
                      >Fee Schedule</a
                    >
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="document-link">
                    <a
                      href="./forms/Monumemt_Direct_Debit_Service_Agreement_Sept2024.pdf"
                      target="_blank"
                      >Direct Debit Service Agreement</a
                    >
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="document-link">
                    <a
                      href="./forms/Monument_FinancialHardshipPolicy_Oct 2024.pdf"
                      target="_blank"
                      >Financial Hardship Policy</a
                    >
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="document-link">
                    <a
                      href="./forms/MPF_Resolving-Complaints-Guide_Oct2024.pdf"
                      target="_blank"
                      >Resolving Complaints Guide</a
                    >
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="document-link">
                    <a href="./forms/TMD_Monument_Sept2024.pdf" target="_blank"
                      >Target Market Determination</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="home-section-break"
          class="section resources section-break"
          ref="home-section-break"
        >
          <div class="container">
            <div class="row text-center" data-aos="fade-up">
              <div class="col">
                <h4 class="mt-4">
                  Our long history in the market brings deep technical knowledge
                  and broad industry experience. This is complemented by the
                  quality of relationships we have cultivated over the years,
                  characterised by high trust and respect in every interaction.
                </h4>
                <br />
                <h4>
                  We firmly believe that our people are the cornerstone of our
                  success.
                </h4>

                <router-link
                  to="/about-us#team"
                  class="btn secondary round mt-4"
                  >Meet the team</router-link
                >
              </div>
            </div>
          </div>
        </section>
        <GetInTouchSection />
      </div>
      <!-- CLARIFY NEXT SECTION -->
    </section>
  </main>

  <FooterSection />
</template>
