import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../components/HomePage.vue";
import ProductsAndServices from "../components/ProductsAndServices.vue";
import AboutUs from "../components/AboutUs.vue";
import ContactUs from "../components/ContactUs.vue";
import ContactUsForm from "../components/ContactUs-form.vue";
import OurResources from "../components/OurResources.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/products-and-services",
    name: "products-and-services",
    component: ProductsAndServices,
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
  },
  {
    path: "/contact-static",
    name: "contact-static",
    component: ContactUs,
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: ContactUsForm,
  },
  {
    path: "/our-resources",
    name: "our-resources",
    component: OurResources,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.APP_BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      return new Promise((resolve) => {
        resolve({ top: 0, left: 0 });
      });
    }
  },
});

export default router;
