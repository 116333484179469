<template>
  <!-- get in touch -->
  <section id="getInTouch" class="get-in-touch">
    <div class="container" data-aos="fade-up">
      <div class="row">
        <div class="col-12 col-md-4">
          <h2>Get started <br class="d-none d-md-block" />today</h2>
        </div>
        <div class="col-12 col-md"></div>
        <div class="col-12 col-md-7">
          <p>
            For more information about our business and services, contact our
            friendly team today or speak to your insurance broker.
          </p>
          <router-link to="/contact-us" class="btn primary round mt-4"
            >Have a chat with us today</router-link
          >
        </div>
      </div>
    </div>
  </section>
  <!-- end get in touch -->
</template>
