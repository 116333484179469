<script setup>
import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";
import GetInTouchSection from "./GetInTouch.vue";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});
</script>

<template>
  <HeaderSection />

  <main id="main">
    <section id="about-us" class="section about">
      <div class="" data-aos="fade-up">
        <div class="container">
          <h2>About <b>Monument</b></h2>
          <h3>Making an impact in everything that we do.</h3>
          <div class="row">
            <div class="col">
              <p class="highlight py-5">
                Whether it's an emerging business or an established organization
                seeking expansion funds, we have the resources and expertise to
                support their financial goals. Our flexible insurance policy
                repayment options can also be tailored to meet the unique
                requirements of various industries.
              </p>
            </div>
          </div>
        </div>
        <!-- ======= Section Break ======= -->
        <section
          id="about-section-break"
          class="section about section-break mt-4"
          ref="about-section-break"
        >
          <div class="container full-width">
            <div class="row" data-aos="fade-up">
              <div class="col-12 col-md-4">
                <div class="card about-feature">
                  <div class="card-body">
                    <div class="icon">
                      <img
                        src="@/assets/img/monument-business-owner.png"
                        alt="Helping business owners"
                      />
                    </div>
                    <h3>Helping business owners</h3>
                    <p>
                      We provide effective cash management that will ensure
                      appropriate coverage at all times - mitigating risk and
                      protecting assets.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card about-feature">
                  <div class="card-body">
                    <div class="icon">
                      <img
                        src="@/assets/img/monument-inspiring.png"
                        alt="Inspiring insurance brokers"
                      />
                    </div>
                    <h3>Inspiring insurance brokers</h3>
                    <p>
                      We forge true partnership and tailor our service to allow
                      them to deliver positive outcomes for their clients
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="card about-feature">
                  <div class="card-body">
                    <div class="icon">
                      <img
                        src="@/assets/img/monument-insurance-industry.png"
                        alt="Enriching the insurance industry"
                      />
                    </div>
                    <h3>Enriching the insurance industry</h3>
                    <p>
                      We are thoughts leaders and invest in education and
                      knowledge sharing to support our industry in the face of
                      increasing insurance premiums.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- End Section Break -->
        <section class="off-white-bg" id="team">
          <div class="container">
            <h2 class="pb-4">Meet the team</h2>
            <p>
              Our long history in the market brings deep technical knowledge and
              broad industry experience. This is complemented by the quality of
              relationships we have cultivated over the years, characterised by
              high trust and respect in every interaction.
            </p>

            <p>
              We firmly believe that our people are the cornerstone of our
              success.
            </p>
            <div class="row team-member">
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Rachael_Lavars.png"
                        alt="Rachael Lavars"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Rachael Lavars
                      </h5>

                      <p class="m-0 role">
                        <span>Chief Executive Officer</span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/rachael-lavars-b5a64a7/"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Tracey_Torney.png"
                        alt="Tracey Torney"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Tracey Torney
                      </h5>

                      <p class="m-0 role">
                        <span>Head of Monument, Sales</span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/tracey-torney-20b55192"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Marcelle_Morgan.png"
                        alt="Marcelle Morgan"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Marcelle Morgan
                      </h5>

                      <p class="m-0 role">
                        <span>Head of Customer Experience</span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/marcelle-morgan-15637875/"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Vickey_Moyes.png"
                        alt="Vickey Moyes"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Vickey Moyes
                      </h5>

                      <p class="m-0 role">
                        <span>Head of Product & Technology </span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/vickey-moyes-2942581b"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Paul_Schofield.png"
                        alt="Paul Schofields"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Paul Schofields
                      </h5>

                      <p class="m-0 role">
                        <span>Head of Sales Enablement</span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/1paulschofield/"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Evangeline_Tng.png"
                        alt="Evangeline Tng"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Evangeline Tng
                      </h5>

                      <p class="m-0 role">
                        <span>National Marketing Manager</span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/evangelinetng/"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Jenny_Nekic.png"
                        alt="Jenny Nekic"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Jenny Nekic
                      </h5>

                      <p class="m-0 role">
                        <span>Head of Finance</span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/jennynekic-obrien/"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Andrew_Davis.png"
                        alt="name"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Andrew Davis
                      </h5>

                      <p class="m-0 role"><span>Credit & Risk Manager</span></p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/andrew-davis-01197651"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Gemma_McKenzie.png"
                        alt="Gemma McKenzie"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Gemma McKenzie
                      </h5>

                      <p class="m-0 role">
                        <span>Business Development Manager QLD / NT / SA</span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/gemma-mckenzie-29557b58/"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Fendi_Safron.png"
                        alt="Fendi Safron"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Fendi Safron
                      </h5>

                      <p class="m-0 role">
                        <span>Business Development Manager NSW</span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/fendi-safron"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Michelle_Cross.png"
                        alt="Michelle Cross"
                      />

                      <h5 class="card-title name highlight mb-2">
                        Michelle Cross
                      </h5>

                      <p class="m-0 role">
                        <span
                          >Business Development Manager NSW / ACT / TAS</span
                        >
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/michelle-cross-8508aa86"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="card team">
                  <div class="card-body" data-aos="fade-up">
                    <div class="photo pb-3">
                      <img
                        class="mb-3"
                        src="@/assets/img/team/Eray_Seker.png"
                        alt="Eray Seker"
                      />

                      <h5 class="card-title name highlight mb-2">Eray Seker</h5>

                      <p class="m-0 role">
                        <span>Business Development Manager VIC </span>
                      </p>

                      <p class="m-0 linkedin">
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/in/eray-seker-549619235"
                          ><span class="linkedin-badge" alt="linkedin"></span
                        ></a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <GetInTouchSection />
      </div>
      <!-- CLARIFY NEXT SECTION -->
    </section>
  </main>

  <FooterSection />
</template>
