<script setup>
import {
  MDBNavbar,
  MDBNavbarToggler,
  // MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

const collapse1 = ref(false);
</script>

<template>
  <MDBNavbar
    expand="lg"
    light
    bg="light"
    container
    id="header"
    class="fixed-top d-flex flex-column"
  >
    <!-- <MDBNavbarBrand
      href="/"
      class="logo mr-auto"
      aria-label="Monument"
    ></MDBNavbarBrand> -->
    <MDBNavbarItem router class="logo mr-auto" aria-label="Monument" to="/"
      >&nbsp;</MDBNavbarItem
    >
    <MDBNavbarToggler
      @click="collapse1 = !collapse1"
      target="#navbarSupportedContent"
      class="p-4"
    ></MDBNavbarToggler>
    <MDBCollapse
      v-model="collapse1"
      id="navbarSupportedContent"
      class="justify-content-end pb-2"
    >
      <MDBNavbarNav class="mb-2 mb-lg-0">
        <MDBNavbarItem router to="/about-us">About Us</MDBNavbarItem>
        <MDBNavbarItem router to="/products-and-services"
          >Our Service</MDBNavbarItem
        >
        <MDBNavbarItem router to="/our-resources">Resources</MDBNavbarItem>
        <MDBNavbarItem router to="/contact-us">Contact Us</MDBNavbarItem>
        <MDBNavbarItem
          class="broker-login"
          href="https://select.elantis.com.au/Custom/Content/Elantis/Select/Login.aspx?Broker=Monument"
          >Broker Login</MDBNavbarItem
        >
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>
</template>
