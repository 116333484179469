<script setup>
/* eslint-disable */
import axios from "axios";
import { MDBInput, MDBTextarea } from "mdb-vue-ui-kit";

import FooterSection from "./FooterSection.vue";
import HeaderSection from "./HeaderSection.vue";

import { onMounted, ref, reactive, computed } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

// const departmentEmails = ref([
//   { name: "Property", emailAddress: "property@monumentpf.com.au" },
//   {
//     name: "Liability",
//     emailAddress: "gl@monumentpf.com.au",
//   },
//   { name: "Care Liability", emailAddress: "careliability@monumentpf.com.au" },
//   { name: "Construction", emailAddress: "construction@monumentpf.com.au" },
//   { name: "Financial Lines", emailAddress: "pr@monumentpf.com.au" },
//   { name: "Transport", emailAddress: "transport@monumentpf.com.au" },
//   { name: "Other", emailAddress: "info@monumentpf.com.au" },
// ]);

const getInitialData = () => ({
  destination: "info@monumentpf.com.au",
  subject: null,
  name: null,
  email: null,
  message: null,
});

let contactForm = reactive({
  destination: "info@monumentpf.com.au",
  subject: null,
  name: null,
  email: null,
  message: null,
});

let formSubmitted = ref(null);

const displayNotification = computed(() => {
  return formSubmitted.value ? true : false;
});

const isDev = ["localhost", "www.gbsau.dev"].includes(location.hostname);

const apiUrl = `https://${
  isDev ? "uat" : "prd"
}-de-api.avantek.dev/api/contact`;
const apiJwt = isDev
  ? "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBU0ZBLUJDLURFViIsInUiOnt9LCJpYXQiOjE3MTAzMDY4OTIsInJvbGVzIjpbIkJyb3dzZXJDbGllbnQiXX0.Szm8aeNgQkQ4wZX2cQ6J3rZwYKfwpr-hzeOqlo4VU_Q"
  : "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBU0ZBLUJDIiwidSI6e30sImlhdCI6MTcxNzYyODQwOSwicm9sZXMiOlsiQnJvd3NlckNsaWVudCJdfQ.SuULQgOB6-yWgtOklnvXm6wa1CQLGE27bTw5y4FnRDY";

async function sendResponse(e) {
  e.target.classList.add("was-validated");

  let emailBody =
    "From: " +
    contactForm.name +
    "\n \n Reply email: " +
    contactForm.email +
    "\n \n Subject: " +
    contactForm.subject +
    "\n \n \n Message: " +
    contactForm.message;

  let formAnswers = {
    destination: "info@monumentpf.com.au",
    subject: contactForm.subject,
    name: contactForm.name,
    email: contactForm.email,
    message: emailBody,
  };

  //  "destination"
  //  "subject"
  //  "name"
  //  "email"
  //  "message"

  try {
    await axios
      .post(apiUrl, formAnswers, {
        headers: { Authorization: `Bearer ${apiJwt}` },
      })
      .then(function (response) {
        if (response.status == 200) {
          formSubmitted.value = true;
        }
      });
  } catch (err) {
    console.log(err);
  }

  e.target.classList.remove("was-validated");

  Object.assign(contactForm, getInitialData());
}
</script>

<template>
  <div>
    <div ref="pageTop"></div>
    <HeaderSection />

    <main id="main">
      <section id="contact-us" class="section">
        <div
          class="side-triangle"
          data-aos="fade-up"
          data-aos-delay="200"
        ></div>
        <div class="container" data-aos="fade-up">
          <h2 class="highlight">Get In Touch<br /></h2>
          <h3 class="highlight">We'd love to hear from you.</h3>

          <div class="row my-5 mb-md-0">
            <div class="col-lg-7 col-12">
              <h4 class="">Drop us a message</h4>
              <form
                id="form mb-4"
                class="needs-validation"
                @submit.prevent="sendResponse"
              >
                <MDBInput
                  type="text"
                  label="Subject"
                  id="subject"
                  v-model="contactForm.subject"
                  wrapperClass="mb-4"
                  required
                  invalidFeedback="Please provide a subject."
                />

                <MDBInput
                  type="text"
                  label="Name"
                  id="name"
                  v-model="contactForm.name"
                  wrapperClass="mb-4"
                  required
                  invalidFeedback="Please provide your name."
                />

                <MDBInput
                  type="email"
                  label="Email address"
                  id="email"
                  v-model="contactForm.email"
                  wrapperClass="mb-4"
                  required
                  invalidFeedback="Please provide your email address."
                />

                <MDBTextarea
                  label="Message"
                  id="message"
                  v-model="contactForm.message"
                  wrapperClass="mb-4"
                  required
                  invalidFeedback="Please provide your message."
                />

                <button class="btn primary round" type="submit">Send</button>

                <div
                  class="alert alert-success alert-dismissible fade show text-start mt-2"
                  role="alert"
                  v-if="displayNotification"
                >
                  Your message was sent successfully.
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    @click="formSubmitted = !formSubmitted"
                  ></button>
                </div>
              </form>
            </div>
            <div class="col-lg-5 col-12 contact-details">
              <h4 class="highlight">Contact details</h4>
              <div class="card unit">
                <div class="card-body">
                  <h5 class="card-title highlight">Office address</h5>
                  <p class="card-text">
                    Suite 2, Level 1<br />
                    50 Pitt Street <br />
                    Sydney, NSW 2000<br />
                  </p>
                  <p class="card-text">
                    <b>Email: </b>
                    <a href="mailto:info@monumentpf.com.au"
                      >info@monumentpf.com.au</a
                    >
                  </p>

                  <h5 class="card-title highlight">Mailing address</h5>
                  <p class="card-text">
                    PO BOX R1873<br />
                    Sydney, NSW 2000
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <FooterSection />
  </div>
</template>
