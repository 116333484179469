<script setup>
import { Carousel, Navigation, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

import { onMounted } from "vue";
import AOS from "aos";

onMounted(() => {
  AOS.init();
});

const reviews = [
  "’The Monument team are always willing to discuss options for my clients. They understand commercial reality and that businesses go through many challenges. Most people want to be able to pay their bills – they sometimes just need a bit of help to get there!  My BDM, Michelle has always provided solutions and I’ve been able to obtain good outcomes for my client.’ – Beth",
  "’I am incredibly pleased with the quality of service the Monument team always provide. Their prompt responses, willingness to assist,  and knowledge is something they should be commended for.’ – Haley",
  "’There are only positive words and thoughts that come to mind when I think about Monument. Everyone is willing to help and a pleasure to deal with. The service is efficient with a very quick response time and a simple easy to complete process. Always a pleasure to deal with the Monument Team.’ – Aziz",
];

const breakpoints2 = {
  // 700px and up
  768: {
    itemsToShow: 1,
    snapAlign: "start",
  },
  // 1024 and up
  992: {
    itemsToShow: 2,
    snapAlign: "start",
  },
};
</script>

<template>
  <!-- testimonial -->
  <section id="testimonials" class="testimonials off-white-bg">
    <div class="container" data-aos="fade-right">
      <div id="clientReviews" class="clientReviews" ref="clientReviews">
        <h2 class="title text-center">
          What Insurance Brokers are saying about us
        </h2>
        <Carousel :breakpoints="breakpoints2" :wrap-around="true">
          <Slide v-for="review in reviews" :key="review">
            <div class="carousel__item">
              <p class="highlight">{{ review }}</p>
            </div>
          </Slide>

          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
  <!-- end testimonial -->
</template>
